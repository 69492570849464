import React, {useEffect} from "react";
import {Badge, Box, CircularProgress, IconButton, Tooltip} from "@mui/material";
import {Favorite, FavoriteBorder} from "@mui/icons-material";
import {auth} from "../../auth";
import {api} from "../../controllers/apiConfig";

const PlurButton = ({entityPlurCount, entityType, entityId}) => {
    const user = auth.getUser();
    const [plurCount, setPlurCount] = React.useState(entityPlurCount);
    const [userPlur, setUserPlur] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        if (!user) return;
        setPlurCount(entityPlurCount);
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }
        fetch(`${api}/${entityType}/${entityId}/checkPlur`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.info === 'False')
                    setUserPlur(false);
                else
                    setUserPlur(true);

            }).catch(e => console.log(e));
    },[entityType, entityPlurCount, entityId, user]);

    const handleChangePlur = () => {
        if (!user || isLoading) return;

        setIsLoading(true);
        const addRemove = userPlur ? 'removePlur' : 'addPlur';
        const addQty = userPlur ? -1 : 1;

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }
        fetch(`${api}/${entityType}/${entityId}/${addRemove}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.isValid)
                    setUserPlur(!userPlur);
                setIsLoading(false);
                setPlurCount(plurCount + addQty);
            })
            .catch(e => {
                console.log(e);
                setIsLoading(false);
            });
    }


    return (
        <Box>
            <Tooltip title={user ? 'Add Plur' : 'Login to add Plur'}>
                {
                    user ?
                        <IconButton size={'small'} onClick={() => handleChangePlur()}>
                            <Badge badgeContent={plurCount} sx={{color: '#fff'}} color={'secondary'}>
                                <PlurIcon isUserPlur={userPlur} isLoading={isLoading} plurCount={plurCount} />
                            </Badge>
                        </IconButton>
                        :
                        <IconButton>
                            <Badge badgeContent={plurCount} sx={{color: '#fff'}} color={'secondary'}>
                                <DisabledPlurIcon />
                            </Badge>
                        </IconButton>
                }
            </Tooltip>
        </Box>
    )
}

const PlurIcon = ({isUserPlur, isLoading}) => {
    if (isLoading)
        return <CircularProgress sx={{color: '#862b9f'}} size={35} />
    if (isUserPlur)
        return <Favorite style={buttonStyle} fontSize='large' />

    return <FavoriteBorder style={buttonStyle} fontSize='large' />
}

const DisabledPlurIcon = () => {
    return <FavoriteBorder style={{color: '#aaaaaa'}} fontSize={'large'} />
}

const buttonStyle = {
    color: '#862b9f'
}

export default PlurButton;