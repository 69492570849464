import {api} from './apiConfig'; //export const api = 'https://localhost:7200/api';
import { auth } from '../auth';
import { startOfDay } from 'date-fns'; //getUser: () => {return JSON.parse(sessionStorage.getItem('user'));}

const pageSizeOptions = [5,15,50];

export const eventController = {
  getAll: (filter, pageNumber, pageSize) => {
    return new Promise((resolve, reject) => {
      if (!pageSizeOptions.find(x => x===pageSize)) reject('Invalid page size.');
      if (pageNumber < 0) reject('Invalid page number.');
      const search = filter.length > 0 ? `search=${filter}&` : '';
      const apiURL = `${api}/events?${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      fetch(apiURL)
        .then(response => response.json())
        .then(page => {resolve(page);})
        .catch(e => {
          reject('Internal Server Error');
          console.log(e);
        });
    })
  },
  get: (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${api}/events/${id}`).then(response => response.json())
        .then(event => resolve(event))
        .catch(() => {
          reject('Failed to load event. Server Error.');
        });
    })
  },
  getNew: () => {
    return {
      id: 'new',
      name: 'New Event',
      about: '',
      img: '',
      thumbnail: '',
      lineupPoster: '',
      startDate: '',
      endDate: '',
      festival: '',
      city: '',
      state: '',
      country: '',
      plurCount: 0,
      facebook: '',
      instagram: '',
      twitter: '',
      youTube: '',
      website: '',
      tickets: '',
      published: false,
      lineupPublished: false,
      schedulePublished: false,
    }
  },
  add: (event) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(event)
      }
      fetch(`${api}/events`, requestOptions)
        .then(() => {
          event.id = event.name.toLowerCase().replaceAll(' ','-');
          resolve(event);
        })
        .catch(e => {
          console.log(e);
          reject('Failed to create event. Server Error');
        });
    })
  },
  update: (event) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      event.startDate = startOfDay(new Date(event.startDate));
      event.endDate = startOfDay(new Date(event.endDate));
      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(event)
      }
      fetch(`${api}/events/${event.id}`, requestOptions)
        .then(() => {
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to update event. Server Error');
        });
    })
  },
  setPublished: (event, published) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      }
      fetch(`${api}/events/${event}/setPublished/${published}`, requestOptions)
        .then(() => {
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to update event. Server Error');
        });
    })
  },
  delete: (event) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      }
      fetch(`${api}/events/${event.id}`, requestOptions)
        .then(() => {
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to Delete. Server Error.');
        });
    });
  },
  updateImage: (id, imageData) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(imageData)
      }

      fetch(`${api}/events/${id}/image`, requestOptions).then(() => {
        resolve('Success');
      }).catch(e => {
        console.log(e);
        reject('Failed to save Image');
      });
    })
  },
  updateLineupPoster: (id, imageData) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(imageData)
      }

      fetch(`${api}/events/${id}/poster`, requestOptions).then(() => {
        resolve('Success');
      }).catch(e => {
        console.log(e);
        reject('Failed to save Image');
      });
    })
  },
  festival: {
    add: (eventId, festivalId) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }
        fetch(`${api}/events/${eventId}/addFestival/${festivalId}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject("Failed to add festival. Server Error.");
          });
      })
    },
    remove: (eventId) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }
        fetch(`${api}/events/${eventId}/removeFestival`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject("Failed to remove festival. Server Error.");
          });
      })
    }
  },
  lineup: {
    get: (eventId, filter, pageNumber, pageSize) => {
      return new Promise((resolve, reject) => {
        if (!pageSizeOptions.find(x => x === pageSize)) reject('Invalid page size.');
        if (pageNumber < 0) reject('Invalid page number.');
        const search = filter.length > 0 ? `search=${filter}&` : '';
        const apiURL = `${api}/events/${eventId}/lineup?${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
        fetch(apiURL)
          .then(response => response.json())
          .then(page => {
            resolve(page);
          })
          .catch(e => {
            console.log(e);
            reject('Internal Server Error');
          });
      });
    },
    add: (eventId, artistId, tier) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }
        console.log(`${api}/events/${eventId}/addArtist/${artistId}/${tier}`);
        fetch(`${api}/events/${eventId}/addArtist/${artistId}/${tier}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to add. Server Error.');
          });
      });
    },
    remove: (eventId, artistId) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }
        fetch(`${api}/events/${eventId}/removeArtist/${artistId}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to remove. Server Error.');
          });
      });
    }
  },
  performances: {
    get: (eventId) => {
      return new Promise((resolve, reject) => {
        fetch(`${api}/events/${eventId}/performances`)
          .then(response => response.json())
          .then(result => {
            resolve(result);
          })
          .catch(e => {
            console.log(e);
            reject('Server Error');
          });
      })
    },
    add: (eventId, artistIds, stage, startTime, endTime) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        startTime =  new Date(startTime - startTime.getTimezoneOffset() * 60000);
        endTime = new Date(endTime - endTime.getTimezoneOffset() * 60000);
        const data = {artistIds, stage, startTime, endTime};
        const requestOptions = {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
          body: JSON.stringify(data)
        };
        fetch(`${api}/events/${eventId}/performances`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to save. Server Error');
          })
      })
    },
    remove: (eventId, performanceId) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'DELETE',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        };
        fetch(`${api}/events/${eventId}/performances/${performanceId}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to delete. Server Error');
          })
      })
    }
  },
  stages: {
    get: (eventId) => {
      return new Promise((resolve, reject) => {
        fetch(`${api}/events/${eventId}/stages`)
          .then(response => response.json())
          .then(result => {
            resolve(result);
          })
          .catch(e => {
            console.log(e);
            reject('Server Error');
          });
      })
    },
    add: (eventId, name, img) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const data = {name, img};
        const requestOptions = {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
          body: JSON.stringify(data)
        };
        fetch(`${api}/events/${eventId}/stages`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to save. Server Error');
          })
      })
    },
    updateName: (eventId, name, newName) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        };
        fetch(`${api}/events/${eventId}/stages/${name}/${newName}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to save. Server Error');
          })
      })
    },
    remove: (eventId, stageName) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'DELETE',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        };
        fetch(`${api}/events/${eventId}/stages/${stageName}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to delete. Server Error');
          })
      })
    },
    updateImage: (id, name, imageData) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
          body: JSON.stringify(imageData)
        }
        fetch(`${api}/events/${id}/stages/${name}/image`, requestOptions).then(() => {
          resolve('Success');
        }).catch(e => {
          console.log(e);
          reject('Failed to save Image');
        });
      })
    }
  },
  tags: {
    get: (id) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'GET',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        }
        fetch(`${api}/events/${id}/tags`, requestOptions)
          .then(response => response.json())
          .then(result => resolve(result))
          .catch(e => {
            console.log(e);
            reject('Failed to get tags. Server Error.');
          });
      })
    },
    add: (id, name) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        };
        fetch(`${api}/events/${id}/addTag/${name}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to save. Server Error');
          })
      })
    },
    remove: (id, name) => {
      return new Promise((resolve, reject) => {
        const user = auth.getUser();
        if (!user) reject('Failed to load user credentials.');
        const requestOptions = {
          method: 'PUT',
          headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        };
        fetch(`${api}/events/${id}/removeTag/${name}`, requestOptions)
          .then(() => resolve('Success'))
          .catch(e => {
            console.log(e);
            reject('Failed to save. Server Error');
          })
      })
    },
  }
}
