import React from "react";
import {SocialIcon} from "react-social-icons";
import {Box} from "@mui/material";

export const SocialLinks = ({entity, size, sx}) => {
  const styles = {
    width: size,
    height: size,
    margin: 4,
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'row', ...sx}}>
      {entity.spotify && entity.spotify.length > 1 ?
          <SocialIcon network='spotify' url={entity.spotify} style={styles} />
        : null}
      {entity.appleMusic && entity.appleMusic.length > 1 ?
          <SocialIcon network='itunes' url={entity.appleMusic} style={styles} />
        : null}
      {entity.soundCloud && entity.soundCloud.length > 1 ?
        <SocialIcon network='soundcloud' url={entity.soundCloud} style={styles} />
        : null}
      {entity.facebook && entity.facebook.length > 1 ?
        <SocialIcon network='facebook' url={entity.facebook} style={styles} />
        : null}
      {entity.instagram && entity.instagram.length > 1 ?
        <SocialIcon network='instagram' url={entity.instagram} style={styles} />
        : null}
      {entity.twitter && entity.twitter.length > 1 ?
        <SocialIcon network='twitter' url={entity.twitter} style={styles} />
        : null}
      {entity.youTube && entity.youTube.length > 1 ?
        <SocialIcon network='youtube' url={entity.youTube} style={styles} />
        : null}
      {entity.tikTok && entity.tikTok.length > 1 ?
        <SocialIcon network='tiktok' url={entity.tikTok} style={styles} />
        : null}
      {entity.website && entity.website.length > 1 ?
        <SocialIcon network='sharethis' url={entity.website} style={styles} />
        : null}
    </Box>
  );
}
