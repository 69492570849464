import React from "react";
import {Box, Typography} from "@mui/material";
import ArtistListResults from "../components/artist/ArtistListResults";

const ArtistList = () => {
    return <Box sx={{width: '100%', marginTop: 2}}>
        <Box sx={{marginY: 2}}>
            <Typography variant={'h3'}>Browse Artists</Typography>
        </Box>
        <ArtistListResults />
    </Box>
}

export default ArtistList;
