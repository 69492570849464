import React, {Component} from "react";
import {
    Box, Card, CardMedia,
    CircularProgress,
    Grid, Pagination, Typography
} from "@mui/material";
import {NavLink} from "react-router-dom";
import {artistController} from "../../controllers/artistController";
import {SocialLinks} from "../social/SocialLinks";
import {Favorite} from "@mui/icons-material";

class ArtistListResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: {},
            pageNumber: 0,
        }
        this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.fetchArtists('', this.state.pageNumber, 50);
    }

    fetchArtists(filter, pageNumber, pageSize){
        this.setState({loading: true});
        artistController.getAll(filter, pageNumber, pageSize)
            .then(page => this.setState({page, loading: false}))
            .catch(e => {
                this.setState({loading: false});
                console.log(e)
            });
    }

    handlePageChange = (event, value) => {
        const newPage = value - 1;
        this.setState({pageNumber: newPage});
        this.fetchArtists('', newPage, 50);
    }

    render() {
        if (this.state.loading){
            return (
                <Box sx={{width: '100%', padding: 12, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <CircularProgress sx={{color: 'lightgray'}} size={60} />
                </Box>
            )
        }

        const page = this.state.page;
        if (!page || !page.items || page.items.length <= 0)
            return <></>;

        return(
            <Box sx={{width: '100%'}}>
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginY: 2}}>
                    <Pagination size={'medium'} count={page.totalPages} color={'primary'} onChange={this.handlePageChange} page={this.state.pageNumber + 1} />
                </Box>
                <Grid container spacing={1}>
                    {page.items.map((artist, index) =>
                        <Grid key={index} item xs={12} md={6} lg={4} >
                            <Card sx={{marginBottom: 2, border: '1px solid darkgray', display: 'flex'}}>
                                <Box sx={{display:'flex', flexDirection: 'row'}}>
                                    <NavLink to={`/artists/${artist.id}`}>
                                        <CardMedia component={'img'} sx={{width: 128, height: 128}} image={artist.thumbnail ? artist.thumbnail : "/static/artist_placeholder.jpg"} alt={artist.name} />
                                    </NavLink>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1, flex: 1 }}>
                                        <NavLink to={`/artists/${artist.id}`}>
                                            <Typography variant={'h4'}>{artist.name}</Typography>
                                        </NavLink>
                                        <Typography sx={{marginTop: 1}}>{artist.genres.map((genre, index) =>
                                            <span key={index}>{`${index > 0 ? ", " : ''}${genre}`}</span>
                                        )}</Typography>
                                        {
                                            artist.plurCount > 0 ?
                                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                    <Favorite color='secondary' fontSize={'small'}/>
                                                    <Typography color='secondary' marginLeft={0.5}>{artist.plurCount}</Typography>
                                                </Box>
                                                :
                                                null
                                        }
                                        <div style={{flex: 1}} />
                                        <SocialLinks size={26} entity={artist} sx={{overflow: 'hidden'}} />
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    )}
                </Grid>
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginY: 2}}>
                    <Pagination size={'medium'} count={page.totalPages} color={'primary'} onChange={this.handlePageChange} page={this.state.pageNumber + 1} />
                </Box>
            </Box>
        )
    }
}

export default ArtistListResults;
