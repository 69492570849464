import {Typography} from "@mui/material";


const NotFoundPage = () => {
    return (
        <Typography>We couldn't find what you were looking for.</Typography>
    )
}

export default NotFoundPage;
