import React, {useState} from "react";
import {searchController} from "../../controllers/searchController";
import {
    Avatar,
    Box,
    CircularProgress, IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField
} from "@mui/material";
import {NavLink} from "react-router-dom";
import {ClearRounded, Search} from "@mui/icons-material";

const SearchBar = ({focused, width}) => {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChangeSearch = (e) => {
        const value = e.target.value;
        setSearch(value);
        if (value.length > 1){
            setLoading(true);
            searchController.search(value)
                .then(newPage => updatePage(newPage))
                .catch(e => {
                    console.log(e);
                    setPage({})
                    setLoading(false);
                })
        } else {
            setPage({});
        }
    }

    const updatePage = (newPage) => {
        if (search.length > 1){
            //Ok to set search results
            if (!page || page === {}){
                //No current page
                setPage(newPage);
                setLoading(false);
                return;
            } else if (parseInt(page.requestedAt) > parseInt(newPage.requestedAt)){
                //Search results arrived after a more recent search
                return;
            }

            //Ok to set results
            setPage(newPage);
            setLoading(false);
        }
    }

    const onSearchCanceled = () => {
        setPage({});
        setSearch('');
    }

    return (
        <Box sx={{backgroundColor: '#171717', width: width}}>
            <TextField
                name="search"
                label="search"
                value={search}
                onChange={handleChangeSearch}
                size='small'
                color='text'
                fullWidth
                focused={focused}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={'start'}>
                        {loading ?
                            <CircularProgress size={20} sx={{color: 'lightgray'}} />
                            :
                            <Search fontSize={'small'} sx={{color: 'lightgray'}} />
                        }
                        </InputAdornment>
                    ),
                    endAdornment: (
                        search && search.length > 0 &&
                        <InputAdornment position={'end'}>
                            <IconButton size={'small'} onClick={onSearchCanceled}>
                                <ClearRounded fontSize={'small'} sx={{color: 'lightgray'}} />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {page && page.items && page.items.length > 0 && !loading &&
            <Box sx={resultsStyle}>
                <List sx={{margin: 0, padding: 0}}>
                    {page.items.map(result =>
                        <NavLink key={result.id} to={`/${result.type}/${result.id}`} onClick={onSearchCanceled}>
                            <ListItem sx={{py: 0, minHeight: 56, backgroundColor: '#212121', borderBottom: '1px solid #171717FF'}}>
                                <ListItemAvatar>
                                    <Avatar alt="user" src={result.thumbnail} />
                                </ListItemAvatar>
                                <ListItemText primary={result.title} secondary={result.subTitle} />
                            </ListItem>
                        </NavLink>
                    )
                    }
                    {page.items.length < page.totalItemsCount &&
                    <ListItem sx={{height: 70, marginBottom: 2}}>
                        <NavLink to={'/'} onClick={onSearchCanceled}>
                            <ListItemText primary={`View all ${page.totalItemsCount} results...`} />
                        </NavLink>
                    </ListItem>}
                </List>
            </Box>
            }
        </Box>
    );
}

const resultsStyle = {
    position: {xs: 'fixed', md: 'absolute'},
    top: {xs: 64, md: 38},
    left: 0,
    maxHeight: {xs: 'calc(100vh - 64px)', md: 'calc(100vh - 60px)'},
    overflowY: 'auto',
    backgroundColor: '#171717FF',
    minWidth: {xs: '100%', md: '150%'},
    zIndex: 10
}

export default SearchBar;
