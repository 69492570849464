import {api} from './apiConfig'; //export const api = 'https://localhost:7200/api';
import { auth } from '../auth'; //getUser: () => {return JSON.parse(sessionStorage.getItem('user'));}

const pageSizeOptions = [5,15,50];

export const postController = {
  getAll: (type, id, filter, pageNumber, pageSize) => {
    return new Promise((resolve, reject) => {
      if (!pageSizeOptions.find(x => x===pageSize)) reject('Invalid page size.');
      if (pageNumber < 0) reject('Invalid page number.');
      const search = filter.length > 0 ? `search=${filter}&` : '';
      const apiURL = `${api}/posts/${type}/${id}?${search}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      fetch(apiURL)
        .then(response => response.json())
        .then(page => {resolve(page);})
        .catch(e => {
          reject('Internal Server Error');
          console.error(e);
        });
    })
  },
  get: (id) => {
    return new Promise((resolve, reject) => {
      const apiUrl = `${api}/posts/${id}`;
      fetch(apiUrl)
          .then(response => response.json())
          .then(post => resolve(post))
          .catch(e => {
            console.error(e);
            reject('Internal Server Error');
          });
    })
  },
  checkOwnership: (id) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      };
      fetch(`${api}/posts/${id}/checkOwnership`, requestOptions)
          .then(response => response.json())
          .then(result => resolve(result))
          .catch(e => reject(e));
    });
  },
  add: (post) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(post)
      }
      fetch(`${api}/posts`, requestOptions)
        .then(() => {
          resolve('Success');
        })
        .catch(e => {
          console.log(e);
          reject('Failed to create. Server Error');
        });
    })
  },
  delete: (id) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      }
      fetch(`${api}/posts/${id}/delete`, requestOptions)
          .then(() => {resolve('success')})
          .catch(e => {
            console.log(e);
            reject('Failed to delete comment. Server error.');
          });
    })
  },
  addComment: (id, comment) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const body = {comment: comment}
      const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
        body: JSON.stringify(body)
      }
      fetch(`${api}/posts/${id}/addComment`, requestOptions)
          .then(() => {resolve('success')})
          .catch(e => {
            console.log(e);
            reject('Failed to create comment. Server error.');
          });
    })
  },
  checkCommentOwnership: (commentId) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      };
      fetch(`${api}/comments/${commentId}/checkOwnership`, requestOptions)
          .then(response => response.json())
          .then(result => resolve(result))
          .catch(e => reject(e));
    });
  },
  deleteComment: (id) => {
    return new Promise((resolve, reject) => {
      const user = auth.getUser();
      if (!user) reject('Failed to load user credentials.');
      const requestOptions = {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json', 'Authorization' : `Bearer ${user.token}`},
      }
      fetch(`${api}/comments/${id}/delete`, requestOptions)
          .then(() => {resolve('success')})
          .catch(e => {
            console.log(e);
            reject('Failed to delete comment. Server error.');
          });
    })
  },
}
