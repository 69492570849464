import React, {useEffect, useState} from "react";
import {auth} from "../../auth";
import {postController} from "../../controllers/postController";
import {Box, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import {Delete, MoreHoriz} from "@mui/icons-material";

const PostActions = ({id, handleRefresh}) => {
    const user = auth.getUser();
    const [isOwned, setIsOwned] = useState(false);
    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);

    useEffect(() => {
        if (!user) return;
        postController.checkOwnership(id)
            .then(result => {
                setIsOwned(result.isValid && result.info && result.info === 'True');
            })
    }, [id, isOwned, user]);

    const handleClick = e => setAnchor(e.currentTarget);

    const handleClose = () => setAnchor(null);

    const handleDelete = () => {
        if (!user || !isOwned) return;
        postController.delete(id)
            .then(() => {
                handleClose();
                handleRefresh();
            })
            .catch(e => alert(e));
    }

    if (!isOwned || !user) return null;

    return(
        <Box>
            <Tooltip title={'Actions'}>
                <IconButton color={'secondary'} onClick={handleClick}>
                    <MoreHoriz />
                </IconButton>
            </Tooltip>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchor}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <MenuItem onClick={handleDelete} disableRipple>
                    <Delete />
                    Delete
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default PostActions;