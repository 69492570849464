import React from "react";
import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import {Formik} from 'formik';
import {auth} from "../auth";
import {NavLink, useNavigate} from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();

    return <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
                const errors = {};
                if (!values.email) {
                    errors.email = <Typography sx={{color: 'purple'}}>*Required</Typography>;
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = <Typography sx={{color: 'purple'}}>*Invalid Email Address</Typography>;
                }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                auth.login(values.email, values.password)
                    .then((user) => {
                        auth.setUser(user);
                        navigate('/');
                    }).catch(e => {
                        setSubmitting(false);
                        alert('failed to login' + e);
                })
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box sx={{display: 'flex', flexDirection: 'column', width: 380}}>
                        <Typography sx={{marginBottom: 2}} variant={'h3'} textAlign={'center'}>Login</Typography>
                        {errors.email && touched.email && errors.email}
                        <TextField
                            type="email"
                            name="email"
                            label='Email'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            size={'small'}
                            sx={textFieldStyle}
                            variant={'filled'}
                            color={'text'}
                        />
                        {errors.password && touched.password && errors.password}
                        <TextField
                            type="password"
                            name="password"
                            label="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            size={'small'}
                            sx={textFieldStyle}
                            variant={'filled'}
                            color={'text'}
                        />
                        <Button type="submit" disabled={isSubmitting} variant={'outlined'} sx={{borderColor: 'purple'}}>
                            <Typography>Login</Typography>
                        </Button>
                        <div style={{height: 30}} />
                        <Divider />
                        <div style={{height: 30}} />
                        <NavLink to={'/account/create'}><Typography color={'secondary'} sx={{textAlign: 'center'}}>Create New Account</Typography></NavLink>
                    </Box>
                </form>
            )}
        </Formik>
    </Box>
}

const textFieldStyle = {
    marginBottom: 2,
    backgroundColor: '#262626'
}

export default Login;
