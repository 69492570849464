import {api} from './apiConfig';

const defaultResultsSize = 15;

export const searchController = {
    search: (search) => {
        return new Promise((resolve, reject) => {
            const apiURL = `${api}/search?search=${search}&pageNumber=0&pageSize=${defaultResultsSize}&requestedAt=${Date.now()}`;
            fetch(apiURL)
                .then(response => response.json())
                .then(page => {resolve(page);})
                .catch(e => {
                    reject('Internal Server Error');
                    console.log(e);
                });
        })
    },
    resultsDefinition: () => {
        return {
            totalItemsCount: 0,
            pageSize: 0,
            pageNumber: 0,
            requestedAt: '',
            items: [{
                title: '',
                subTitle: '',
                thumbnail: '',
                type: ''
            }],
        }
    }
}
