import React, {Component} from "react";
import {
    Avatar,
    Box, Card, CardHeader, CardMedia,
    CircularProgress,
    Grid,
} from "@mui/material";
import {format} from "date-fns";
import {NavLink} from "react-router-dom";
import {eventController} from "../../controllers/eventController";

class EventListResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: {},
        }
    }

    componentDidMount() {
        this.fetchEvents('', 0, 50);
    }

    fetchEvents(filter, pageNumber, pageSize){
        this.setState({loading: true});
        eventController.getAll(filter, pageNumber, pageSize)
            .then(page => this.setState({page, loading: false}))
            .catch(e => {
                this.setState({loading: false});
                console.log(e)
            });
    }

    render() {
        if (this.state.loading){
            return (
                <Box sx={{width: '100%', padding: 12, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    <CircularProgress sx={{color: 'lightgray'}} size={60} />
                </Box>
            )
        }

        const page = this.state.page;
        if (!page || !page.items || page.items.length <= 0)
            return <></>;

        return(
            <Box sx={{width: '100%'}}>
                <Grid container spacing={1}>
                    {page.items.map((event, index) =>
                        <Grid key={index} item xs={12} md={6} lg={4} >
                            <NavLink to={`/events/${event.id}`}>
                                <Card sx={{marginBottom: 2, border: '1px solid darkgray'}}>
                                        <CardHeader
                                            avatar={<Avatar src={event.thumbnail} /> }
                                            title={event.name}
                                            subheader={`${format(new Date(event.startDate), 'P')} - ${format(new Date(event.endDate), 'P')}`}
                                        />
                                    <CardMedia
                                        component='img'
                                        alt={event.name}
                                        height='194'
                                        src={event.lineupPoster ? event.lineupPoster : event.img}
                                    />
                                </Card>
                            </NavLink>
                        </Grid>
                    )}
                </Grid>
            </Box>
        )
    }
}

export default EventListResults;
