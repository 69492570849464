import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MainNavbar from './MainNavbar';
import {Container} from "@mui/material";
import Footer from "./Footer";

const MainLayoutRoot = styled('div')(
    ({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        maxWidth: '100%'
    })
);

const MainLayout = () => (
    <MainLayoutRoot>
        <MainNavbar />
        <Container maxWidth={'lg'} sx={{flex: 1}}>
            <Outlet />
        </Container>
        <Footer />
    </MainLayoutRoot>
);

export default MainLayout;
