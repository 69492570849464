import React from "react";
import PropTypes from "prop-types";
import {Box} from "@mui/material";

const YouTubeEmbedded = ({ src, title }) => (
    <Box sx={{  position: 'relative', overflow: 'hidden', width: '100%', paddingTop: '56.25%'}}>
        <iframe
            style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%'}}
            src={`https://www.youtube.com/embed/${src}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={title}
        />
    </Box>
);

YouTubeEmbedded.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default YouTubeEmbedded;
