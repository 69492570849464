import React from "react";
import {Box} from "@mui/material";
import ArtistList from "./ArtistList";
import EventList from "./EventList";

const Home = () => {
    return (
        <Box sx={{height: '100%'}}>
            <EventList />
            <ArtistList />
        </Box>
    );
}

export default Home;
