import React, {Component} from "react";
import {artistController} from "../../controllers/artistController";
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {format} from "date-fns";
import {NavLink} from "react-router-dom";

class ArtistEventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
        }
    }

    componentDidMount() {
        this.fetchEvents();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id !== this.props.id){
            this.fetchEvents();
        }
    }

    fetchEvents(){
        artistController.events.getAll(this.props.id)
            .then(events => this.setState({events}))
            .catch(e => console.log(e));
    }

    render() {
        if (!this.state.events || this.state.events.length <= 0)
            return <></>;

        return(
            <Box sx={{width: '100%'}}>
                <List>
                {this.state.events.map((event, index) =>
                    <NavLink  key={index} to={`/events/${event.id}`}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar alt={event.name} src={event.thumbnail} />
                            </ListItemAvatar>
                            <ListItemText primary={event.name} secondary={`${format(new Date(event.startDate), 'P')} - ${format(new Date(event.endDate), 'P')}`} />
                        </ListItem>
                    </NavLink>
                )}
                </List>
            </Box>
        )
    }
}

export default ArtistEventList;
