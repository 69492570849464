import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Avatar,
    Box, Button,
    Card,
    CardContent,
    CardHeader, TextField,
    Typography
} from "@mui/material";
import {postController} from "../controllers/postController";
import {format} from "date-fns";
import PlurButton from "../components/plur/PlurButton";
import {auth} from "../auth";
import CommentActions from "../components/posts/CommentActions";
import {Reply} from "@mui/icons-material";

const textFieldStyle = {
    marginBottom: 2,
    backgroundColor: '#262626'
}

const PostPage = () => {
    let {id} = useParams();
    const [post, setPost] = useState(null);
    const [body, setBody] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);
    const [replyCommentId, setReplyCommentId] = useState(0);
    const user = auth.getUser();

    useEffect(() => {
        if (!post || id !== post.id){
            postController.get(id).then(p => setPost(p)).catch(e => console.log(e));
        }
    }, [id, post]);

    const refresh = () => {
        postController.get(id).then(p => setPost(p)).catch(e => console.log(e));
    }

    const onSubmitComment = () => {
        if (!user || !body || body.length < 1)
            return;

        setSubmitting(true);

        postController.addComment(id, body)
            .then(() => {
                setBody('');
                refresh();
                setSubmitting(false);
            })
            .catch(e => {
                console.log(e);
                setSubmitting(false);
            });
    }

    if (!post)
        return null;

    return (
        <Box sx={{marginTop: 2}}>
            <Card sx={{marginBottom: 2, border: '1px solid #616161'}}>
                <CardHeader
                    title={post.username}
                    subheader={format(new Date(post.createdAt), 'Pp')}
                    avatar={<Avatar alt={post.username} src={post.userThumbnail} />}
                    action={<PlurButton entityType='posts' entityId={post.id} entityPlurCount={post.plurCount} />}
                />
                <CardContent sx={{py: 0}}>
                    <Typography variant={'h3'}>{post.title}</Typography>
                    <Typography sx={{color: '#c4c4c4'}}>{post.body}</Typography>
                </CardContent>
            </Card>
            <Box hidden={!user} sx={{width: '100%', paddingX: 1}}>
                <TextField
                    name={'body'}
                    value={body}
                    sx={{...textFieldStyle}}
                    size={'small'}
                    label={'Add Comment'}
                    onChange={(e) => setBody(e.target.value)}
                    multiline
                    color='text'
                    variant={'filled'}
                    fullWidth={true}
                    disabled={isSubmitting}
                />
                {user ?
                    <Box hidden={!user || !body || body.trim().length <= 0}>
                        <Box sx={{marginBottom: 2, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Button disabled={isSubmitting} color={'secondary'} variant='outlined'
                                    onClick={onSubmitComment}>Comment</Button>
                            <Typography variant={'subtitle2'} sx={{
                                paddingX: 1,
                                fontStyle: 'italic'
                            }}>{`Commenting as ${user.username}`}</Typography>
                        </Box>
                    </Box>
                    : null
                }
            </Box>
            {post.comments.map((comment, index) =>
                <Box key={index} sx={{marginBottom: 1, borderBottom: '1px solid white'}}>
                    <Card>
                        <CardHeader
                            title={comment.username}
                            subheader={format(new Date(comment.createdAt), 'Pp')}
                            avatar={<Avatar alt={comment.username} src={comment.userThumbnail} />}
                            action={
                            <Box sx={{display:'flex', flexDirection: 'row'}}>
                                <CommentActions id={comment.id} handleRefresh={refresh} />
                                <PlurButton entityType={`comments`} entityId={comment.id} entityPlurCount={comment.plurCount} />
                            </Box>
                        }
                            />
                        <CardContent sx={{py: 0}}>
                            <Typography>{comment.body}</Typography>
                        </CardContent>
                        { user &&
                            <CardContent>
                                {replyCommentId !== comment.id ?
                                    <Button startIcon={<Reply/>} color={'secondary'}
                                            onClick={() => setReplyCommentId(comment.id)}>Reply</Button>
                                    :
                                    <Box sx={{marginX: 2, display: 'flex', flexDirection: 'row'}}>
                                        <Reply/>
                                        <TextField
                                            name='reply'
                                            sx={{backgroundColor: '#262626'}}
                                            size='small'
                                            multiline
                                            label={`reply as ${user.username}`}
                                            color='text'
                                            variant='filled'
                                            fullWidth={true}
                                            disabled={isSubmitting}
                                        />
                                    </Box>
                                }
                            </CardContent>
                        }
                    </Card>
                    {comment.children.map((child, i) =>
                        <Card key={i} sx={{marginLeft: 4}}>
                            <CardHeader
                                title={child.username}
                                subheader={format(new Date(child.createdAt), 'Pp')}
                                avatar={<Avatar alt={comment.username} src={child.userThumbnail} />}
                                action={
                                    <Box sx={{display:'flex', flexDirection: 'row'}}>
                                        <CommentActions id={child.id} handleRefresh={refresh} />
                                        <PlurButton entityType={`comments`} entityId={child.id} entityPlurCount={child.plurCount} />
                                    </Box>
                                }
                            />
                            <CardContent sx={{py: 0}}>
                                <Typography>{child.body}</Typography>
                            </CardContent>
                        </Card>
                    )}
                </Box>
            )}
        </Box>
    )
}
export default PostPage;