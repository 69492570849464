import React, {useEffect} from "react";
import {Avatar, Box, Divider, Tab, Tabs, Typography} from "@mui/material";
import {Comment, Person, Schedule} from "@mui/icons-material";
import {artistController} from "../../controllers/artistController";
import ArtistDetails from "./ArtistDetails";
import ArtistEventList from "./ArtistEventList";
import PostsList from "../posts/PostsList";

const ArtistTabs = ({id}) => {
    const [value, setValue] = React.useState(0);
    const [artist, setArtist] = React.useState(null);

    useEffect(() => {
        if (!artist || artist.id !== id){
            artistController.get(id)
                .then(result => setArtist(result));
        }
    }, [id, artist])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {artist ? <Avatar alt={artist.name} src={artist.thumbnail} sx={{marginRight: 1}} /> : null}
                <Typography variant={'h3'} sx={{marginY: 2}}>{artist ? artist.name : ' '}</Typography>
            </Box>
            <Divider />
            <Box>
                <Tabs textColor={'secondary'} indicatorColor={"secondary"} variant={'fullWidth'} value={value} onChange={handleChange}>
                    <Tab label={"Info"} icon={<Person />} value={0} sx={{fontSize: 'large'}} />
                    <Tab label={"Events"} icon={<Schedule />} value={1} sx={{fontSize: 'large'}} />
                    <Tab label={"Posts"} icon={<Comment />} value={2} sx={{fontSize: 'large'}} />
                </Tabs>
            </Box>
            <Divider />
            {artist ?
            <>
                <Box sx={{width: '100%'}} hidden={value !== 0}>
                    <ArtistDetails artist={artist} />
                </Box>
                <Box sx={{width: '100%'}} hidden={value !== 1}>
                    <ArtistEventList id={id} />
                </Box>
                <Box sx={{width: '100%'}} hidden={value !== 2}>
                    <PostsList id={id} entityType={'artists'} />
                </Box>
            </> :
                null
            }
        </Box>
    );
}

export default ArtistTabs;