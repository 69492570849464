import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import CreateAccountEmailPassword from "../components/accountCreation/CreateAccountEmailPassword";

const CreateAccount = () => {
    const [stage] = React.useState('email');
    const [accountCreated, setAccountCreated] = React.useState(false);

    if (accountCreated){
        return (
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 8}}>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography variant={'h3'} textAlign={'center'}>
                        Account created successfully. Please login to continue.
                    </Typography>
                    <NavLink to={'/account/login'}>
                        <Button variant={'outlined'} sx={{marginTop: 3, borderColor: 'purple'}}>
                            <Typography>Login</Typography>
                        </Button>
                    </NavLink>
                </Box>
            </Box>
        );
    }

    if (stage === 'email')
        return <CreateAccountEmailPassword textFieldStyle={textFieldStyle} setAccountCreated={setAccountCreated} />
}

const textFieldStyle = {
    marginBottom: 2,
    backgroundColor: '#262626'
}

export default CreateAccount;
