import React from "react";
import {Avatar, Box, CircularProgress, Divider, Grid, Typography} from "@mui/material";
import {SocialLinks} from "../social/SocialLinks";
import NotFoundPage from "../../pages/NotFoundPage";
import PlurButton from "../plur/PlurButton";

const ArtistDetails = ({artist}) => {
    if (artist == null){
        return (
            <Box sx={{width: '100%', padding: 12, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <CircularProgress sx={{color: 'lightgray'}} size={60} />
            </Box>
        )
    }

    const getSpotifyId = () => {
        if (!artist.spotify || artist.spotify.length < 10)
            return '';

        const lastSlash = artist.spotify.lastIndexOf('/');
        const queryStart = artist.spotify.indexOf('?');

        if (queryStart > 0)
            return artist.spotify.slice(lastSlash+1, queryStart);

        return artist.spotify.slice(lastSlash+1, artist.spotify.length);
    }

    const spotifyId = getSpotifyId();

    let spotifyEmbedUrl = '';
    if (spotifyId.length > 4){
        spotifyEmbedUrl = `https://open.spotify.com/embed/artist/${spotifyId}?utm_source=generator&theme=0`;
    }

    if (!artist || !artist.id)
        return <NotFoundPage />;

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2}}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Avatar src={artist.img} alt={artist.name} sx={{width: 300, height: 300}} />
                        <Typography variant={'h3'} sx={{textAlign: 'center', marginTop: 2}}>{artist.name}</Typography>
                        <Typography sx={{marginTop: 1}} textAlign={'center'}>{artist.genres.map((genre, index) =>
                            <span key={index}>{`${index > 0 ? ", " : ''}${genre}`}</span>
                        )}</Typography>
                        <PlurButton entityId={artist.id} entityType={'artists'} entityPlurCount={artist.plurCount} />
                        <SocialLinks size={30} entity={artist} sx={{my: 1}} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    {artist.about ?
                        <>
                            <Divider/>
                            <Typography variant={'h3'} sx={{marginY: 1}}>About</Typography>
                            <Divider />
                            <Typography sx={{marginY: 1}}>{artist.about}</Typography>
                            <div style={{height: 20}} />
                            <Divider />
                        </>
                        :
                        null
                    }
                    {spotifyEmbedUrl &&  <iframe key={spotifyEmbedUrl} title={'Artist Top Tracks - Spotify'} src={spotifyEmbedUrl} width={'100%'} height={'390'} frameBorder={0}  />}
                </Grid>
            </Grid>
        </Box>
    );
}

export default ArtistDetails;
