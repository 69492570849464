import React, {useState} from "react";
import {Box, Button, TextField, Typography} from "@mui/material";
import {auth} from "../../auth";
import {api} from "../../controllers/apiConfig";

const ErrorText = ({text}) => <Typography variant={'caption'} sx={{paddingBottom: 1, color: 'purple'}}>*{text}</Typography>;

const CreateAccountEmailPassword = ({textFieldStyle, setAccountCreated}) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [touched, setTouched] = useState({
        email: false,
        password: false,
        confirmPassword: false,
        username: false,
    })
    const [isSubmitting, setSubmitting] = useState(false);

    const handleBlur = e => {
        const touches = touched;

        const name = e.target.name;
        touches[name] = true;

        if (name === 'email'){
            checkEmail();
        } else if (name === 'username'){
            checkUsername();
        } else if (name === 'password'){
            checkPassword();
        } else if (name === 'confirmPassword'){
            checkConfirmPassword();
        }
        setTouched(touches);
    }

    const checkUsername = () => {
        setUsernameError('Validating...');
        if (!username || username.length < 2){
            setUsernameError('Username is required');
            return;
        }

        auth.checkUsername(username)
            .then(response => {
                if (response.isValid)
                    setUsernameError('')
                else
                    setUsernameError('Username is taken');
            });
    }

    const checkEmail = () => {
        setEmailError('Validating...');
        if (email === '') {
            setEmailError('Email is required');
            return;
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            setEmailError('Invalid Email address');
            return;
        }

        auth.checkEmail(email)
            .then(response => {
                if (response.isValid){
                    console.log(response);
                    setEmailError('');
                }
                else
                    setEmailError('There is already an account with this Email');
            });
    }

    const checkPassword = () => {
        setPasswordError('Validating...');
        if (!password) {
            setPasswordError('Minimum 6 characters with at least one letter and one number');
        } else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/i.test(password)){
            setPasswordError('Minimum 6 characters with at least one letter and one number');
            if (confirmPassword)
                checkConfirmPassword();
        } else {
            setPasswordError('');
        }
    }

    const checkConfirmPassword = () => {
        setConfirmPasswordError('Validating...');
        if (!confirmPassword || confirmPassword !== password){
            setConfirmPasswordError('Passwords do not match');
        } else {
            setConfirmPasswordError('');
        }
    }

    const submit = () => {
        setSubmitting(true);
        if (emailError || passwordError || confirmPasswordError || usernameError || !email || !password || !confirmPassword || !username){
            alert('Please fix all errors before submitting');
            setSubmitting(false);
            return;
        }

        const data = {
            email,
            password,
            confirmPassword,
            username
        }
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }
        fetch(`${api}/account/signup`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.isValid)
                    setAccountCreated(true);
                else
                    alert('Failed: ' + result.info);
                setSubmitting(false);
            })
            .catch(e => {
                console.log(e);
                setSubmitting(false);
            });
    }

    return (
        <Box sx={{marginTop: 8, marginBottom: 8, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', width: 380}}>
                <Typography sx={{marginBottom: 2}} variant={'h3'} textAlign={'center'}>Create Account</Typography>
                {emailError && touched.email && <ErrorText text={emailError} /> }
                <TextField
                    type="email"
                    name="email"
                    label='Email'
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={handleBlur}
                    value={email}
                    size={'small'}
                    sx={textFieldStyle}
                    color='text'
                    variant={'filled'}
                />
                {usernameError && touched.username && <ErrorText text={usernameError} /> }
                <TextField
                    type="text"
                    name="username"
                    label='Username'
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={handleBlur}
                    value={username}
                    size={'small'}
                    sx={textFieldStyle}
                    color='text'
                    variant={'filled'}
                />
                {passwordError && touched.password && <ErrorText text={passwordError} /> }
                <TextField
                    type="password"
                    name="password"
                    label="Password"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={handleBlur}
                    value={password}
                    size={'small'}
                    sx={textFieldStyle}
                    color='text'
                    variant={'filled'}
                />
                {confirmPasswordError && touched.confirmPassword && <ErrorText text={confirmPasswordError} /> }
                <TextField
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={handleBlur}
                    value={confirmPassword}
                    size={'small'}
                    sx={textFieldStyle}
                    color='text'
                    variant={'filled'}
                />
                <Button type="submit" onClick={() => submit()} disabled={isSubmitting} variant={'outlined'} sx={{borderColor: 'purple'}}>
                    <Typography>Create Account</Typography>
                </Button>
            </Box>
        </Box>
    );
}

export default CreateAccountEmailPassword;