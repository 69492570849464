import React, {useState} from "react";
import {NavLink} from 'react-router-dom';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container, Divider, Drawer,
    IconButton, List, ListItem, ListItemIcon, ListItemText,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import Logo from './Logo';
import {Clear, Logout, Menu as MenuIcon, PersonAddAlt, PersonOutline, Search as SearchIcon} from "@mui/icons-material";
import {auth} from "../auth";
import SearchBar from "./search/SearchBar";

const iconStyle = {color: 'lightgray', minWidth: 42}

const pages = ['events', 'artists', 'squads'];
const profilePages = [
    {
        name: 'profile',
        to: '/account/profile',
        icon: <PersonOutline sx={iconStyle} />
    },
    {
        name: 'logout',
        to: '/account/logout',
        icon: <Logout sx={iconStyle} />
    },
]
const loginPages = [
    {
        name: 'login',
        to: '/account/login',
        icon: <PersonOutline sx={iconStyle} />
    },
    {
        name: 'create account',
        to: '/account/create',
        icon: <PersonAddAlt sx={iconStyle} />
    },
]

const MainNavbar = () => {
    const user = auth.getUser();
    const [navDrawerOpen, setNavDrawerOpen] = useState(false);
    const [userDrawerOpen, setUserDrawerOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

    const handleOpenNavMenu = () => setNavDrawerOpen(true);
    const handleOpenUserMenu = () => setUserDrawerOpen(true);
    const handleCloseNavMenu = () => setNavDrawerOpen(false);
    const handleCloseUserMenu = () => setUserDrawerOpen(false);
    const toggleSearchOpen = () => setSearchOpen(!searchOpen);

    return (
        <AppBar position="static" sx={{height: 64}}>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex', paddingTop: 1 }, marginRight: 3 }}>
                        <NavLink to={'/'}>
                            <Logo height={22} />
                        </NavLink>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor={'left'} open={navDrawerOpen} onClose={handleCloseNavMenu}>
                            <Box sx={{width: 250}}>
                                <List>
                                    <ListItem><Typography sx={{marginTop: 1}} variant={'h4'}>MENU</Typography></ListItem>
                                    <Divider sx={{marginY: 1}}/>
                                    {pages.map((page, index) =>
                                        <ListItem key={index}>
                                            <NavLink to={`/${page}`} onClick={handleCloseNavMenu}>
                                                <ListItemText>{page.toUpperCase()}</ListItemText>
                                            </NavLink>
                                        </ListItem>
                                    )}
                                </List>
                            </Box>
                        </Drawer>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center', paddingTop: 1 }}>
                        <IconButton sx={{padding: .5}} onClick={toggleSearchOpen}>
                            {searchOpen ?
                                <Clear fontSize='medium' sx={{color: '#ffffff'}} /> :
                                <SearchIcon fontSize='medium' sx={{color: '#ffffff'}} />
                            }
                        </IconButton>
                        {searchOpen ?
                            <Box sx={{ borderRadius: 1, position: 'relative'}}>
                                <SearchBar focused={true} width={240} />
                            </Box>
                            :
                            <NavLink to={'/'}>
                                <Logo height={20} />
                            </NavLink>
                        }
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, marginRight: 1, backgroundColor: '#171717', borderRadius: 1, position: 'relative'}}>
                        <SearchBar focused={false} width={280} />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <NavLink key={page} to={`/${page}`}>
                                <Button sx={{ my: 2, color: 'white', display: 'block', px: 2 }}>
                                    {page}
                                </Button>
                            </NavLink>

                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Profile">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="user" src={user && user.thumbnail ? user.thumbnail : "/static/gremlin_normal_256.png"} />
                            </IconButton>
                        </Tooltip>
                        <Drawer anchor={'right'} open={userDrawerOpen} onClose={handleCloseUserMenu}>
                            <Box sx={{width: 280}}>
                                <List>
                                    <ListItem><Typography sx={{marginTop: 1}} variant={'h4'}>ACCOUNT</Typography></ListItem>
                                    <Divider sx={{marginY: 1}}/>
                                    {user ?
                                        <>
                                            {profilePages.map((page, index) =>
                                                <NavLink to={page.to} key={index} onClick={handleCloseUserMenu}>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            {page.icon}
                                                        </ListItemIcon>
                                                        <ListItemText>{page.name.toUpperCase()}</ListItemText>
                                                    </ListItem>
                                                </NavLink>
                                            )}
                                        </>
                                        :
                                        <>
                                            {loginPages.map((page, index) =>
                                                <NavLink to={page.to} key={index} onClick={handleCloseUserMenu}>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            {page.icon}
                                                        </ListItemIcon>
                                                        <ListItemText>{page.name.toUpperCase()}</ListItemText>
                                                    </ListItem>
                                                </NavLink>
                                            )}
                                        </>
                                    }
                                </List>
                            </Box>
                        </Drawer>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default MainNavbar;
