import React, {useEffect} from "react";
import {Avatar, Box, Divider, Tab, Tabs, Typography} from "@mui/material";
import EventLineup from "./EventLineup";
import EventDetails from "./EventDetails";
import {Comment, Groups, Landscape} from "@mui/icons-material";
import {eventController} from "../../controllers/eventController";
import PostsList from "../posts/PostsList";

const EventTabs = ({id}) => {
    const [value, setValue] = React.useState(0);
    const [event, setEvent] = React.useState(null);

    useEffect(() => {
        if (!event || event.id !== id){
            eventController.get(id)
                .then(result => setEvent(result));
        }
    }, [id, event])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {event ? <Avatar alt={event.name} src={event.thumbnail} sx={{marginRight: 1}} /> : null}
                <Typography variant={'h3'} sx={{marginY: 2}}>{event ? event.name : ' '}</Typography>
            </Box>
            <Box>
                <Tabs textColor={'secondary'} indicatorColor={"secondary"} variant={'fullWidth'} value={value} onChange={handleChange}>
                    <Tab label={"Info"} icon={<Landscape />} value={0} sx={{fontSize: 'large'}} />
                    <Tab label={"Lineup"} icon={<Groups />} value={1} sx={{fontSize: 'large'}} />
                    <Tab label={"Posts"} icon={<Comment />} value={2} sx={{fontSize: 'large'}} />
                </Tabs>
            </Box>
            <Divider />
            <Box sx={{width: '100%'}} hidden={value !== 0}>
                <EventDetails event={event} />
            </Box>
            <Box sx={{width: '100%'}} hidden={value !== 1}>
                <EventLineup id={id} />
            </Box>
            <Box sx={{width: '100%'}} hidden={value !== 2}>
                <PostsList entityType='events' id={id} />
            </Box>
        </Box>
    );
}

export default EventTabs;