import React from "react";
import EventListResults from "../components/event/EventListResults";
import {Box, Typography} from "@mui/material";

const EventList = () => {
    return <Box sx={{width: '100%', marginTop: 2}}>
        <Box sx={{marginY: 2}}>
            <Typography variant={'h3'}>Upcoming Events</Typography>
        </Box>
        <EventListResults />
    </Box>
}

export default EventList;
