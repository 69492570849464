import React, {useEffect} from "react";
import {postController} from "../../controllers/postController";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    TextField,
    Typography
} from "@mui/material";
import {format} from "date-fns";
import {Add, Check, Close, Comment} from "@mui/icons-material";
import {auth} from "../../auth";
import PlurButton from "../plur/PlurButton";
import {NavLink} from "react-router-dom";
import PostActions from "./PostActions";

const textFieldStyle = {
    marginBottom: 2,
    backgroundColor: '#262626'
}

const PostsList = ({entityType, id}) => {
    const [page, setPage] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [body, setBody] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);
    const user = auth.getUser();

    useEffect(() => {
        if (page) return;
        postController.getAll(entityType, id, '', 0, 50)
            .then(result => {
                setPage(result);
            })
            .catch(e => console.log(e));
    }, [entityType, id, page]);

    const getPosts = () => {
        postController.getAll(entityType, id, '', 0, 50)
            .then(result => {
                setPage(result);
            })
            .catch(e => console.log(e));
    }

    const onSubmit = () => {
        if (title.length < 2 || submitting || !user)
            return;

        setSubmitting(true);

        const post = {
            title: title,
            entityId: id,
            entityType: entityType
        }

        if (body && body.length > 0)
            post.body = body;

        postController.add(post)
            .then(() => {
                getPosts();
                setTitle('');
                setBody('');
                setSubmitting(false);
                setOpen(false);
            }).catch(e => {
            console.log(e);
            setSubmitting(false);
            alert('Failed to create post.');
        });
    }

    if (!page || page === {})
        return null;

    return (
        <Box>
            <div style={{height: 10}} />
            {user ?
                <Box hidden={!open || !user} sx={{width: '100%'}}>
                    <Typography sx={{margin: 2}} variant={'h5'}>{`Posting as ${user.username}`}</Typography>
                    <TextField
                        name={'title'}
                        value={title}
                        sx={textFieldStyle}
                        size={'small'}
                        label={'Title (Required)*'}
                        onChange={(e) => setTitle(e.target.value)}
                        color='text'
                        variant={'filled'}
                        fullWidth={true}
                    />
                    <TextField
                        name={'body'}
                        value={body}
                        sx={textFieldStyle}
                        size={'small'}
                        label={'Body'}
                        onChange={(e) => setBody(e.target.value)}
                        multiline
                        color='text'
                        variant={'filled'}
                        fullWidth={true}
                    />
                </Box>
                :
                null
            }
            {user ?
                <>
                    {open ?
                        <Button
                            disabled={title.length < 2 || submitting}
                            color={'success'}
                            variant={'outlined'}
                            startIcon={<Check />}
                            sx={{marginRight: 1}}
                            onClick={onSubmit}
                        >Submit</Button>
                        : null
                    }
                    <Button
                        onClick={() => setOpen(!open)}
                        color={'secondary'}
                        variant={'outlined'}
                        startIcon={open ? <Close/> : <Add/>}
                    >
                        {open ? 'Cancel' : 'New Post'}
                    </Button>
                </>
                :
                null
            }

            <div style={{height: 10}} />
            {page.items.map((post, index) =>
                <Card key={index} sx={{marginBottom: 2, border: '1px solid #616161'}}>
                    <CardHeader
                        title={post.username}
                        subheader={format(new Date(post.createdAt), 'Pp')}
                        avatar={<Avatar alt={post.username} src={post.userThumbnail} />}
                        action={
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <PostActions id={post.id} handleRefresh={getPosts}/>
                                <PlurButton entityType='posts' entityId={post.id} entityPlurCount={post.plurCount} />
                            </Box>
                        }
                    />
                    <NavLink to={`/posts/${post.id}`}>
                        <CardActionArea>
                            <CardContent sx={{py: 0}}>
                                <Typography variant={'h3'}>{post.title}</Typography>
                                <Typography sx={{color: '#c4c4c4'}}>{post.body}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </NavLink>
                    <CardActions>
                        <NavLink to={`/posts/${post.id}`}>
                            <Button color='secondary' startIcon={<Comment/>}>{`Comments (${post.commentCount})`}</Button>
                        </NavLink>
                    </CardActions>
                </Card>
            )}
        </Box>
    )
}

export default PostsList;
