import {api} from "./controllers/apiConfig";

export const auth =  {
  getUser: () => {return JSON.parse(localStorage.getItem('user'));},
  setUser: (user) => {localStorage.setItem('user', JSON.stringify(user))},
  login: (email, password) => {
    return new Promise((resolve, reject) => {
      const data = {email, password};
      const requestOptions ={
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      }
      fetch(`${api}/account/login`, requestOptions)
          .then(response => response.json())
          .then(user => {
            resolve(user);
          })
          .catch(e => {
            console.log(e);
            reject(e);
          });
    })
  },
  logout: (navigate) => {
    localStorage.removeItem('user');
    navigate('/');
  },
  silentLogout: () => {
    localStorage.removeItem('user');
  },
  createAccount: (data) => {
    return new Promise((resolve) => {
      const requestOptions ={
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      }
      fetch(`${api}/account/create`, requestOptions)
          .then(response => response.json())
          .then(result => resolve(result))
          .catch(e => console.log(e));
    })
  },
  checkUsername: (username) => {
    return new Promise((resolve) => {
      fetch(`${api}/account/checkUsername/${username}`)
          .then(response => response.json())
          .then(result => {
            resolve(result);
          })
          .catch(e => console.log(e));
    })
  },
  checkEmail: (email) => {
    return new Promise((resolve) => {
      fetch(`${api}/account/checkEmail/${email}`)
          .then(response => response.json())
          .then(result => {
            resolve(result);
          })
          .catch(e => console.log(e));
    })
  }
}
