import React, {Component} from "react";
import {
    Avatar,
    Box,
    Card,
    CardHeader,
    Grid,
    Typography
} from "@mui/material";
import {eventController} from "../../controllers/eventController";
import {NavLink} from "react-router-dom";

class EventLineup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineup: [],
        }
    }

    componentDidMount() {
        this.fetchLineup();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.id !== this.props.id){
            this.fetchLineup();
        }
    }

    fetchLineup(){
        eventController.lineup.get(this.props.id, '', 0, 50)
            .then(page => this.setState({lineup: page.items}))
            .catch(e => console.log(e));
    }

    render() {
        if (!this.state.lineup || this.state.lineup.length <= 0)
            return <></>;

        return(
            <Box sx={{width: '100%', marginTop: 2}}>
                <Grid container spacing={1}>
                    {this.state.lineup.map((artist, index) =>
                        <Grid key={index} item xs={12} sm={6} md={3}>
                            <NavLink to={`/artists/${artist.id}`}>
                                <Card sx={{boxShadow: 'none'}}>
                                    <CardHeader
                                        sx={{margin: 0, padding: 1}}
                                        avatar={
                                            <Avatar src={artist.thumbnail} alt={artist.name} sx={{width: 48, height: 48}} />
                                        }
                                        title={<Typography>{artist.name}</Typography>}
                                        subheader={
                                            <Typography sx={{marginTop: 1, fontSize: 'small'}}>
                                                {artist.genres.map((genre, index) =>
                                                    <span key={index}>{`${index > 0 ? ", " : ''}${genre}`}</span>
                                                )}
                                            </Typography>
                                        }
                                    />
                                </Card>
                            </NavLink>
                        </Grid>
                    )}
                </Grid>
            </Box>
        )
    }
}

export default EventLineup;
