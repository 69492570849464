import React from "react";
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import {format} from "date-fns";
import {SocialLinks} from "../social/SocialLinks";
import YouTubeEmbedded from "../video/YouTubeEmbedded";
import PlurButton from "../plur/PlurButton";

const EventDetails = ({event}) => {
    if (event == null){
        return (
            <Box sx={{width: '100%', padding: 12, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <CircularProgress sx={{color: 'lightgray'}} size={60} />
            </Box>
        )
    }

    if (!event || !event.id)
        return null;

    return (
        <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', marginTop: 2}}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <a href={event.website} target={"_blank"} rel="noreferrer">
                            <img alt={event.name} src={event.img} style={{width: '100%', maxWidth: 300}} />
                        </a>
                        <Typography variant={'h3'} sx={{textAlign: 'center', marginTop: 2}}>{event.name}</Typography>
                        <Typography sx={{marginTop: 1}} textAlign={'center'}>{`${event.city}, ${event.state}, ${event.country}`}</Typography>
                        <Typography sx={{marginTop: 1}} textAlign={'center'}>{format(new Date(event.startDate), 'P')} - {format(new Date(event.endDate), 'P')}</Typography>
                        <PlurButton entityPlurCount={event.plurCount} entityType={'events'} entityId={event.id} />
                        <SocialLinks size={30} entity={event} sx={{my: 1}} />
                        <Typography>{event.about}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    {event.lineupPoster &&
                        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                            <a href={event.lineupPoster} target='_blank' rel="noreferrer">
                                <img alt={event.name + ' lineup poster'} style={{maxWidth: '100%', maxHeight: 660}} src={event.lineupPoster}/>
                            </a>
                        </Box>
                    }
                </Grid>
                {event.trailer &&
                    <Grid item xs={12} xl={12}>
                        <YouTubeEmbedded src={event.trailer} title={event.name} />
                    </Grid>
                }
            </Grid>
        </Box>
    );
}

export default EventDetails;
