import {Box, Container, Typography} from "@mui/material";


const Footer = () => {
    return (
        <Box sx={{height: 64, width: `100%`, backgroundColor: '#121212', display: 'flex', alignItems: 'center'}}>
            <Container maxWidth="lg">
                <Typography textAlign={'center'}>Copyright 2022 by Bass Gremlin</Typography>
            </Container>
        </Box>
    );
}

export default Footer;
