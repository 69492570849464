import React from "react";
import {useParams} from "react-router-dom";
import NotFoundPage from "./NotFoundPage";
import EventTabs from "../components/event/EventTabs";
import ArtistTabs from "../components/artist/ArtistTabs";

const EntityPage = () => {
    let {type, id} = useParams();

    switch (type){
        case 'events':
            return <EventTabs id={id} />
        case 'artists':
            return <ArtistTabs id={id} />;
        default:
            return <NotFoundPage />;
    }
}

export default EntityPage;
