import MainLayout from "./components/MainLayout";
import Home from "./pages/Home";
import EntityPage from "./pages/EntityPage";
import EventList from "./pages/EventList";
import ArtistList from "./pages/ArtistList";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import CreateAccount from "./pages/CreateAccount";
import PostPage from "./pages/PostPage";


const routes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: ':type/:id', element: <EntityPage /> },
            { path: 'events', element: <EventList /> },
            { path: 'artists', element: <ArtistList /> },
            { path: 'posts/:id', element: <PostPage /> },
            { path: 'account/login', element: <Login /> },
            { path: 'account/logout', element: <Logout /> },
            { path: 'account/create', element: <CreateAccount /> },
            { path: '/', element: <Home /> },
        ]
    }
];

export default routes;
