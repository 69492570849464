import React from "react";
import {auth} from "../auth";
import {NavLink, useNavigate} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";

const Logout = () => {
    const navigate = useNavigate();
    auth.logout(navigate);

    return <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 8}}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant={'h3'} textAlign={'center'}>
                You have been logged out.
            </Typography>
            <NavLink to={'/'}>
                <Button variant={'outlined'} sx={{marginTop: 3, borderColor: 'purple'}}>
                    <Typography>Return Home</Typography>
                </Button>
            </NavLink>
        </Box>
    </Box>
}
export default Logout;
